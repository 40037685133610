$(document).ready(function () {
  $('#horizontal-navbar #open-search-box').on('click', function () {
    $('#horizontal-navbar .search-to-input').removeClass('d-none');
    $('#horizontal-navbar #close-search-box').css('display','flex');
    $('#horizontal-navbar #open-search-box').css('display', 'none');

  });

  $('#horizontal-navbar #close-search-box').on('click', function () {
    $('#horizontal-navbar .search-to-input').addClass('d-none');
    $('#horizontal-navbar #close-search-box').css('display','none');
    $('#horizontal-navbar #open-search-box').css('display', 'flex');
  });

  $('#horizontal-navbar #search-icon').hover(
    function () {
      $(this).find("circle, line").attr("stroke", "#252729");
    }, function () {
      $(this).find("circle, line").attr("stroke", "white");
    }
  );

  $(document).on('keypress', function (event) {
    const target = $(event.target);

    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      if (target.prop('id') === 'searchPC') {
        if (target.val() === '') {
          event.preventDefault();
        }
      } else {
        return;
      }
    }
    if (target.is('input') || target.is('textarea')) {
      return;
    }

    $("#searchPC").focus();
  });
});
